import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { plectoDataSourceDTO, plectoMemberDTO, plectoTeamDTO, settings } from './plecto.types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class plectoService {

  constructor(private http: HttpClient,) { }
  url = 'https://plecto.leadcm.com/api/v1/setup/'
  
  getMembers(username: string, password: string): Observable<plectoMemberDTO[]>{
    const url = this.url + 'members'
    const data = {
      username,
      password
    }    
    return this.http.post<plectoMemberDTO[]>(url, data)
  }
  getTeams(username: string, password: string): Observable<plectoTeamDTO[]>{
    const url = this.url + 'teams'
    const data = {
      username,
      password
    }    
    return this.http.post<plectoTeamDTO[]>(url, data)
  }

  getDataSources(username: string, password: string): Observable<plectoDataSourceDTO[]>{
    const url = this.url + 'datasources'
    const data = {
      username,
      password
    }    
    return this.http.post<plectoDataSourceDTO[]>(url, data)
  }

  getSettings(username: string, password: string): Observable<settings>{
    const url = this.url + 'getsettings'
    const data = {
      username,
      password
    }    
    return this.http.post<settings>(url, data)
  }

  createDefaultSetup(userId: string, plectoUsername: string, plectoPassword: string): Observable<settings>{
    const url = this.url + 'setupDefault'
    const data = {
      userId,
      plectoUsername,
      plectoPassword
    }    
    return this.http.post<settings>(url, data)
  }

  sendSettings(settings: settings): Observable<settings>{
    const url = this.url + 'settings'
    return this.http.post<settings>(url, settings)
  }

}

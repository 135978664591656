import { Component, OnInit } from '@angular/core';
import { plectoDataSourceDTO, plectoMemberDTO, settings } from './plecto.types';
import { plectoService } from './plecto.service';

@Component({
  selector: 'app-users',
  templateUrl: './plecto.component.html',
  styleUrls: ['./plecto.component.css']
})
export class plectoComponent implements OnInit {

  constructor(private usersService: plectoService) { }

  ngOnInit(): void {
    
  }
  showMsg: boolean
  plectoMembers: plectoMemberDTO[]
  users: {shortnum: string, plectoMemberId: number|undefined}[]
  dataSources: plectoDataSourceDTO[]
  selectedDataSource: number
  settings: settings = {
    userId: undefined,
    username: undefined,
    password: undefined,
    dataSource: undefined,
    users: undefined,
    defaultUser: undefined,
    fields: undefined,
  }

  callExample = {
    none: "",
    callId: "Call ID",    
    type: "Call type",    
    clientPhone: "Client's phone number",
    lineNumber: "Line number",
    startTime: "Start time",
    answerTime: "Answer time",
    endTime: "End time",
    callDuration: "Call duration in seconds",
    talkDuration: "Talk duration in seconds",
    callDurationText: "Call duration in text view",
    talkDurationText: "Talk duration in text view",
    userId: "Account ID",
    shortNum: "Operator's short number",
    recordName: "Link to the record",
    goal: "Goal",
    tag: "Tag",
    comment: "Comment",
  }

  callFields = Object.keys(this.callExample)

  async login(){
    this.plectoMembers = await this.usersService.getMembers(this.settings.username, this.settings.password).toPromise()
    this.users=[{shortnum: "",plectoMemberId: undefined}]
      for(const member of this.plectoMembers )
        if (member.email===this.settings.username)
          this.settings.defaultUser = member
        
    this.dataSources = await this.usersService.getDataSources(this.settings.username, this.settings.password).toPromise()    
    this.usersService.getSettings(this.settings.username, this.settings.password)
        .subscribe( data => {
          if(data)
          this.loadSettings(data)}
          )
  }

  addUserTableRow(){
    this.users.push({shortnum: "", plectoMemberId: undefined})
  }

  deleteUserTableRow(index) {
    this.users.splice(index, 1);
  }

  setupDefault(){
    if (this.settings.username && this.settings.password && this.settings.userId)
      this.usersService.createDefaultSetup(this.settings.userId, this.settings.username, this.settings.password)
      .subscribe(
        data => this.login()
      )
  }

  saveSettings(){
    //console.log(this.users)
    this.settings.fields = new Array()
    this.settings.users = {}
    for (const user of this.users){
      
      if (user.shortnum && user.plectoMemberId){
        this.settings.users[user.shortnum] = this.plectoMembers[user.plectoMemberId]
      }
    }

    for (const field of this.dataSources[this.selectedDataSource].fields){
      
      if (field.callField && field.callField !== "none")
        this.settings.fields.push({plectoField: field.name, callField: field.callField})
    }
      

    this.settings.dataSource = this.dataSources[this.selectedDataSource]
    console.log(this.settings)    
    this.usersService.sendSettings(this.settings).subscribe( data => {
      this.settings = {
        userId: undefined,
        username: undefined,
        password: undefined,
        dataSource: undefined,
        users: undefined,
        defaultUser: undefined,
        fields: undefined,
      }
      this.users = new Array()
      this.selectedDataSource = undefined
      if(data){
        this.showMsg = true
        this.loadSettings(data)
      }
      
    })
    
  }
  loadSettings(data: settings){
    this.settings = data
    this.settings.username = data.username
    this.settings.password = data.password
    this.users = new Array()
    for(const user of Object.keys(data.users)){
      this.users.push({shortnum: user, plectoMemberId:  this.plectoMembers.findIndex(i => i.uuid === data.users[user].uuid)})
    }
    if (this.users.length === 0)
      this.users=[{shortnum: "",plectoMemberId: undefined}]
    this.selectedDataSource = this.dataSources.findIndex(i => i.uuid === data.dataSource.uuid)

    for (const field of this.dataSources[this.selectedDataSource].fields){
      field.callField = data.fields.find(i => i.plectoField === field.name)?.callField
    }
  }

}


<div>
<input [(ngModel)]="settings.username" placeholder="username"/>
<input [(ngModel)]="settings.password" placeholder="password" type="password"/>
<button (click) = login()>OK</button>
</div>

<div *ngIf="showMsg">
      <p class="alert alert-success">
          <strong>Successfull!</strong>
      </p>    
  </div>

<div *ngIf="users">
Webphone account ID: <input [(ngModel)]="settings.userId"/>
<table  class="table">
    <thead>
        <th>Webphone short number</th>
        <th>Plecto member</th>
    </thead>
    <tbody>
        <tr *ngFor="let user of users; let i = index">
            <td><input [(ngModel)]="user.shortnum"/></td>
            <td>
                <select [(ngModel)]="user.plectoMemberId">
                    <option *ngFor="let plectoMember of plectoMembers" [value]="plectoMembers.indexOf(plectoMember)">                    
                    {{plectoMember.name}}
                    </option>
                </select>
            </td>
            <td>
                <button class="btn btn-default" type="button" (click)="deleteUserTableRow(i)">delete</button>
            </td>
        </tr>
    </tbody>
    <button class="btn btn-default" type="button" (click)="addUserTableRow()">Add</button>
</table>
</div>
<div *ngIf="dataSources">
    Choose datasource to use: 
    <select [(ngModel)]="selectedDataSource">
        <option *ngFor="let dataSource of dataSources" [value]="dataSources.indexOf(dataSource)" >                    
        {{dataSource.title}}
        </option>
    </select>
</div>
<table *ngIf="dataSources[selectedDataSource]" class="table">
    <thead>
        <th>Field name</th>
        <th>Field type</th>
        <th>Call field</th>
    </thead>
    <tbody>
        <tr *ngFor="let field of dataSources[selectedDataSource].fields">
            <td >{{field.name}}</td>
            <td>{{field.input}}</td>
            <td>
                <select [(ngModel)]="field.callField">
                    <option *ngFor="let key of callFields" [value]="key" >                    
                    {{callExample[key]}}
                    </option>
                </select>
            </td>
        </tr>
    </tbody>
</table>



<button *ngIf="settings.userId&&settings.username&&settings.password&&settings.defaultUser" class="btn btn-default" type="button" (click)="saveSettings()">Save</button>
<button *ngIf="settings.username&&settings.password&&settings.userId" class="btn btn-default" type="button" (click)="setupDefault()">Setup default</button>